import React from "react"

interface DocumentDownloadProps {
  title: string
  file: string
  image: string
  children: React.ReactNode
}

export const DocumentDownload: React.FC<DocumentDownloadProps> = ({
  title,
  file,
  image,
  children,
}) => {
  return (
    <div className="flex mt-16 document-download">
      <a
        title={`Document download link for: ${title}`}
        href={file}
        className="w-1/6 h-full mr-4 border-2 md:flex-shrink-0 no-icon border-quill-gray"
      >
        <img alt={title} src={image} />
      </a>
      <div className="w-4/5">
        <h3 className="mt-0 font-serif text-base font-semibold sm:text-lg md:text-xl">
          {title}
        </h3>
        <p className="mb-0">{children}</p>
        <a href={file}>Download</a>
      </div>
    </div>
  )
}
