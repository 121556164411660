import React from "react"

interface Props {
  children: React.ReactNode
  image: string
}

export const TextWithSideArt: React.FC<Props> = ({ image, children }) => {
  return (
    <div className={image ? "flex mb-6" : ""}>
      {image ? (
        <img alt="" className="object-cover w-12 pt-5 md:w-20" src={image} />
      ) : null}
      <div className="flex flex-col mt-5 ml-6">{children}</div>
    </div>
  )
}
