import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
  GatsbyImage as Img,
  getSrc,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image"

import previous from "../../static/media/images/previous.svg"
import next from "../../static/media/images/next.svg"

export const Gallery = () => {
  const {
    allGalleryJson: { images },
  } = useStaticQuery<{
    allGalleryJson: {
      images: {
        node: {
          description: string
          image: ImageDataLike
        }
      }[]
    }
  }>(graphql`
    {
      allGalleryJson(sort: { fields: file___base, order: ASC }) {
        images: edges {
          node {
            description
            image: file {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState(0)

  function _goToPrevSlide() {
    if (currentIndex === 0) return

    setCurrentIndex((prevState) => prevState - 1)
  }

  function _goToNextSlide() {
    // Exiting the method early if we are at the end of the images array.
    // We also want to reset currentIndex and translateValue, so we return
    // to the first image in the array.
    if (currentIndex === images.length - 1) {
      return setCurrentIndex(0)
    }

    // This will not run if we met the if condition above
    setCurrentIndex((prevState) => prevState + 1)
  }

  return (
    <div className="flex flex-col">
      <div className="gallery-border" />

      <div className="flex flex-row justify-between my-8">
        <button
          title="Previous"
          className="flex self-center mr-2 md:-ml-8 lg:-ml-16"
          onClick={() => _goToPrevSlide()}
        >
          <img title="Previous icon" src={previous}></img>
        </button>

        {images.map(({ node }, i) => {
          const nodeImage = getImage(node.image)
          return (
            <div
              key={`${node.description}-${i}`}
              className={`${
                currentIndex === i ? "" : "hidden"
              } w-full flex flex-col`}
            >
              {nodeImage ? (
                <a
                  title="Gallery image link"
                  href={getSrc(node.image)}
                  key={i}
                  className="md:flex-shrink-0"
                >
                  <Img image={nodeImage} alt={node.description} />
                </a>
              ) : null}

              <p className="self-center mt-2 text-gray-600">
                {node.description}
              </p>
            </div>
          )
        })}

        <button
          title="Next"
          className="flex self-center ml-2 md:-mr-8 lg:-mr-16"
          onClick={() => _goToNextSlide()}
        >
          <img title="Next icon" src={next}></img>
        </button>
      </div>

      <div className="gallery-border" />
    </div>
  )
}
