import React from "react"

interface Props {
  children: React.ReactNode
  image: string
}

export const Blockquote: React.FC<Props> = ({ image, children }) => {
  return (
    <div className={image ? "flex mb-6" : ""}>
      {image ? (
        <img alt="" className="object-cover w-12 pt-5 md:w-24" src={image} />
      ) : null}
      <blockquote>
        <p>{children}</p>
      </blockquote>
    </div>
  )
}
